export function getLocalStorageData(item: string) {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(item)
  }
}
export function setLocalStorageData(key: string, data: string) {
  if (typeof window !== 'undefined') {
    return localStorage.setItem(key, data)
  }
}
